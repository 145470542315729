






import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  components: {
    MAttachment: () => import("@/components/molecules/m-attachment.vue"),
  },
  props: {
    ids: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    rowAttrs: {
      type: Object,
      required: false,
      default: undefined,
    },
    colAttrs: {
      type: Object,
      required: false,
      default: undefined,
    },
    deletable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
});
